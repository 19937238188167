@import './src/scss/variables';
@import './src/scss/app';
@import './src/scss/calendar';

.grey-container {
  width: 600px;
  margin: 10px auto;
  background-color: lightgrey;
  padding: 10px;
  border-radius: 10px;
  text-align: center;

  h2 {
    font-size: 64px;
    padding: 10px 0;
  }
}

.text-capitalize {
  text-transform: capitalize;
}

.widget__result-wrap {
	margin: 0;
}